import {
    initializeFaro as coreInit,
    getWebInstrumentations,
    ReactIntegration
} from '@grafana/faro-react'

import {TracingInstrumentation} from '@grafana/faro-web-tracing'

export function initializeFaro() {
    

    const faro = coreInit({
        url: 'https://faro-collector-prod-us-east-0.grafana.net/collect/ff3365443dbc3ec8d0d01a6ebdce18b8',
        app: {
            name: 'plnhow-webapp-react',
            version: '1.0.0',
            environment: 'production'
        },
        instrumentations: [
            ...getWebInstrumentations({
                captureConsole: true
            }),
            new TracingInstrumentation(),
            new ReactIntegration()
        ]
    })

    faro.api.pushLog(['Faro was initialized'])
    return faro
    
}
